@font-face {
  font-family: "Rubik";
  src: local("Rubik"),
   url("./fonts/Rubik/static/Rubik-Regular.ttf") format("truetype");
  }

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.listStyle  li {
  padding: 10px;
}

.listStyle2  li {
  padding: 5px;
}

.drive-text{
  font-size: 18px;
}

.drive-text > p > span{
  color: rgb(182, 94, 31);
  font-size: 20px;
  font-weight: bold;
}

.drive-content >span{
  color: rgb(182, 94, 31);
  font-size: 20px;
  font-weight: bold;
}

.cursor{
  cursor: pointer;
}


