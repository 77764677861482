*{
  font-family: Rubik;
}
.header-logo{
  height:55px;
  padding-left:20px;
}
.footer-logo{
  height:70px;
}

.nav-item {
  padding-right: 50px;
}
.nav-item a{
  font-size: large;
  color:#707070;
  padding-right: 50px;
}
.nav-item a:hover{
  color:#014C7A;
}

.carousel-caption {
    transform: translateY(-50%);
    bottom: initial;
    top: 50%;
    text-align: left;
}


.text-mcolor{
  color:#014C7A;
}
.bg-mcolor{
  background-color:#014C7A;
}

.bg-heighlight{
  background-color:#09DEFD;
}



/* #btn-slider{
  height:15px;
  width:15px;
  border-radius: 50%;
}
#btn-slider>.active{
  height:15px;
  width:40px;
  border-radius: 20px;
  background-color: red;
} */


.hoversection {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #014C7A;
  overflow: hidden;
  width: 100%;
  height:0;
  transition: .5s ease;
  opacity: 0.9;
}

.hoversection:hover .overlay {
  bottom: 0;
  height: 100%;
}

.text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width:80%;
}

.drive-img img{
  text-align: center;
  width:120px;
  margin-bottom: 20px;
  transition: .5s ease;
}
.drive-img img:hover{
  text-align: center;
  width:110px;
  margin-bottom: 20px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.socialicon li{
  cursor: pointer;
  margin: 0;
  color:#09DEFD;
  border: 0px;
  padding: 10px;
}
.socialicon li:hover{
  transition: .5s ease;
  border: 0px;
  color:#ffffff;
}
.footer-nav {
  list-style-type: none;
  color:#ffffff;
}
.footer-nav p{
  color:#09DEFD;
  text-transform: uppercase;
  margin-bottom: 0px;
  padding-top: 20px;
}
.footer-nav li a{
  padding-top: 5px;
  cursor: pointer;
  text-decoration: none;
  color:#ffffff;
}
.footer-nav li a:hover{
  transition: .5s ease;
  color:#09DEFD;
  letter-spacing: 1px;
}



.footerbg{
 background-image: url("../public/images/map.png");
  background-repeat: no-repeat;
  background-color: #014C7A;
  background-size: 80% 80%;
  background-position: center;
  
}

.footercontact img{
  border:solid #ffffff 1px;
  width:25px;
  background-color: #ffffff;
  margin-right: 10px;
}

.footercontact p{
  font-size: small;
}





.navbar .megamenu{ padding: 1rem; }
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
	
	.navbar .has-megamenu{position:static!important;}
	.navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }
	
}	
/* ============ desktop view .end// ============ */


/* ============ mobile view ============ */
@media(max-width: 991px){
	.navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
		overflow-y: auto;
	    max-height: 90vh;
	    margin-top:10px;
	}
}
/* ============ mobile view .end// ============ */


.navbar-nav > li>a{
  color:#747474 !important;
  font-weight: 500;
  font-size:medium;
  
}
.navbar-nav > li>a:hover{
  transition: .5s ease;
}

.megamenu{
  transition: .5s ease;
}

.navbar-toggler {
  color:#014C7A !important;
}
.col-megamenu li {
  margin-top: 5px;
}
.col-megamenu li a{
  text-decoration: none;
}
.col-megamenu li a:hover{
  text-decoration: none;
  transition: .5s ease;
  letter-spacing: 1px;
}
.fullmenu{
  box-shadow: 0px 5px 8px 0px #000000;
}

.title{
  color:#014C7A;
  font-size: 3vh;
}
.title i{
  color:#09DEFD;
}
.Services-nav img{
  width: 130px;
  float: left;
  padding-right: 10px;
  margin-right: 10px;
  border-right:solid #09DEFD 1px;
}
.Services-nav a{
  text-decoration: none;
}
.Services-nav a> p{
  font-size: medium;
}







.inner-heading{
  position: absolute;
  bottom:20%;
  left:80px;
}
.leadershipSection img{
  width:200px;
}

.text-justify{
  text-align: justify;
}


.whymarquee-content{
  list-style-type: none;
}
.whymarquee-content li{
  padding-top:20px;
  text-align: justify;
}

.careermenu{  
  text-align: left;
  width:250px;
  color: #747474;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}



.careermenu:hover{
  color: #747474;
  background-color: #f8f8f8;
}

.tab-pane{
  width:100% !important;
}
.job-content{
  border-left:solid #014C7A 1px;
  min-height:300px;
}




/* ---------------- Locations Section------------------------ */

.flag{
  width:25px;
  margin-right: 8px;
  box-shadow: 0px 2px 3px 0px #747474;
}
.location-card{
  min-height: 480px;
}
.location-card p{
  font-size: 14px;  
}






.list-style-none{
  list-style: none;
}


@media screen and (max-width: 600px) {

  .pattner_carousel {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }

  .main_carousel{
    margin-top: 70px;
  }

  .main_carousel .display-6{
    font-size: 0.8em;
  }

  .main_carousel .blockquote{
    font-size: 0.50em;
  }
}



@media screen and (min-width: 600px) {
  .pattner_carousel_sm {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

.carousel-inner img {
  width: 100%;

}

.carousel-item{
  min-height: 200px;
}

/*

.service-item {
  padding: 60px 30px;
  border: 2px solid #f7f7f7;
  border-radius: 5px;
  transition: all .3s;
  margin-bottom: 30px;
}

.service-item:hover {
  background-color: #fff;
  border: 2px solid transparent;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.service-item .icon {
  margin-top: 10px;
  width: 80px;
  height: 80px;
  display: inline-block;
  text-align: center;
  line-height: 80px;
  background-color: #f7f7f7;
  border-radius: 50%;
}

.service-item .icon img {
    width: 38px;
    height: 38px;
}

.service-item h4 {
  font-size: 22px;
  font-weight: 700;
  color: #2a2a2a;
  margin-bottom: 15px;
}

*/


.psa-keyFeature .service-item {
  padding: 30px;
  transition: all ease-in-out 0.4s;
  background: #ffffff;
  height: 100%;
  box-shadow: 0px 0 60px 0 rgba(72, 86, 100, 0.2);
  width: 100%;
  text-align: center;
  border-radius: 50%;
  
}

.psa-keyFeature .service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0 60px 0 rgba(72, 86, 100, 0.5);
}


.featured-services .service-item {
  padding: 30px;
  transition: all ease-in-out 0.4s;
  background: #ffffff;
  height: 100%;
  box-shadow: 0px 0 60px 0 rgba(72, 86, 100, 0.2);
  width: 100%;
}

.featured-services .service-item .icon {
  margin-bottom: 10px;
}

.featured-services .service-item .icon i {
  color: #014C7A;
  font-size: 36px;
  transition: 0.3s;
}

.featured-services .service-item h4 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 24px;
}

.featured-services .service-item h4 a {
  color: #485664;
  transition: ease-in-out 0.3s;
}

.featured-services .service-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.featured-services .service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0 60px 0 rgba(72, 86, 100, 0.5);
}

.featured-services .service-item:hover h4 a {
  color: #014C7A;
}

/*--------------------------------------------------------------
# Services Section
--------------------------------------------------------------*/
.services .img {
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid rgba(72, 86, 100, 0.4);
}

.services .img img {
  transition: 0.6s;
}

.services .details {
  padding: 20px 10px;
  padding-bottom: 5px;
  margin: -40px 15px 0 15px;
  transition: all ease-in-out 0.3s;
  background: #ffffff;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.5);

  /*background: rgb(34,94,195);
  background: -moz-linear-gradient(0deg, rgba(34,94,195,0.9) 0%, rgba(62,78,192,0.9) 13%, rgba(144,53,242,0.9) 85%, rgba(118,0,164,0.9) 100%);
  background: -webkit-linear-gradient(0deg, rgba(34,94,195,0.9) 0%, rgba(62,78,192,0.9) 13%, rgba(144,53,242,0.9) 85%, rgba(118,0,164,0.9) 100%);
  background: linear-gradient(0deg, rgba(34,94,195,0.9) 0%, rgba(62,78,192,0.9) 13%, rgba(144,53,242,0.9) 85%, rgba(118,0,164,0.9) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#225ec3",endColorstr="#7600a4",GradientType=1);*/
}

.services .details .icon {
  margin: 0;
  width: 40px;
  height: 40px;
  background: #014C7A;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 12px;
  transition: ease-in-out 0.3s;
  position: absolute;
  top: -20px;
  left: calc(50% - 20px);
  border: 6px solid #ffffff;
}

.services .details h3 {
  color: #1a1f24;
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 22px;
  transition: ease-in-out 0.3s;
}

.services .details h6 {
  color: #1a1f24;
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 12px;
  transition: ease-in-out 0.3s;
}

.services .details p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .service-item:hover .details h3 {
  color: #014C7A;
}

.services .service-item:hover .details h6 {
  color: #014C7A;
}

.services .service-item:hover .details .icon {
  background: #ffffff;
  border: 2px solid #014C7A;
}

.services .service-item:hover .details .icon i {
  color: #014C7A;
}

.services .service-item:hover .img img {
  transform: scale(1.2);
}


.job-desc i{
  color: #014C7A;
  margin-right: 10px;
}

.job-desc p{
  display: inline;
}

.btn-marquee{
  background-color: #135680;
  color: #ffffff;
}

.btn-marquee:hover{
  background-color: #014C7A;
  color: #ffffff;
}

.btn-black{
  background-color: #303030;
  color: #ffffff;
}

.btn-black:hover{
  background-color: #000000;
  color: #ffffff;
}

.width-100{
  width:100%;
}

.btn-block{
  width: 100%;
}



/********************** Banner Images **************************/

/* ---------------- Partnership Section------------------------ */
.Partnership-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/partnership.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

/* ---------------- Downloads ection------------------------ */
.downloads-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/downloads.jpg");
  background-repeat: no-repeat;
  background-color: #a3c5c4;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

/* ---------------- Career Section------------------------ */
.Career-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/career.jpg");
  background-repeat: no-repeat;
  background-color: #00366f;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

/* ---------------- About Section------------------------ */
.AboutBanner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/about-us.jpg");
  background-repeat: no-repeat;
  background-color: #014C7A;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

/* ---------------- Events Section------------------------ */
.Events-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/events.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}
/* ---------------- Page not found Section------------------------ */
.pnf-Banner{
  margin-top:70px;
  background-image: url("../public/images/banners/pnf-banner.jpg");
  background-repeat: no-repeat;
  background-color: #a3c5c4;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

/* ---------------- AIML Section------------------------ */
.AIML-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/aiml.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

/* ---------------- AIML Section------------------------ */
.AnalogMixedSol-Banner{
  margin-top:70px;
  background-image: url("../public/images/banners/Analog_Mixed.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

.Membership-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/membership.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

.Gallery-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/gallery.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

/* ---------------- PSA Section------------------------ */
.PSA-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/psa.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

.LeadershipBanner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/leadership.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
  
}

.DigitalBanner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/digital.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

.FPGABanner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/fpga.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
  
}

.StorageBanner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/storage.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
  
}

.AnaMixBanner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/analog-mixed.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
  
}

.AcademiaBanner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/academia-engagement.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
  
}

.Locations-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/contact.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

.LeadershipThought-Banner{
  margin-top:70px;
  background-image: url("../public/images/bannersNew/throught-leadership.jpg");
  background-repeat: no-repeat;
  background-color: #000000;
  background-size: 100% 100%;
  background-position: center;
  min-height: 450px;
  position: relative;
}

@media screen and (max-width: 600px) {

  .Partnership-Banner, .downloads-Banner, .Career-Banner, .AboutBanner, .Events-Banner, .pnf-Banner, .AIML-Banner, 
  .AnalogMixedSol-Banner, .Membership-Banner, .Gallery-Banner, .PSA-Banner, .LeadershipBanner, .DigitalBanner, 
  .FPGABanner, .StorageBanner, .AnaMixBanner, .AcademiaBanner, .Locations-Banner, .LeadershipThought-Banner{
    min-height: 200px;
    background-size: cover;
  }

  .inner-heading{
    position: absolute;
    top:40%;
    left:40px;
  }

  .inner-heading .display-6{
    font-size: 1.2em;
  }

  .inner-heading .blockquote{
    font-size: 0.80em;
  }

}

.list-content{
  line-height: 1.8em;
}

.list-content > li > h5{

  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-orange{
  background-color: #af600b;
  color: #ffffff;
}

.btn-orange:hover{
  background-color: #8d4d0a;
  color: #ffffff;
}

.socialicon{
  font-size: 2em;
}

.socialicon li a:visited{
  color:#09DEFD;
}

.socialicon li a{
  color:#09DEFD;
}

.newCard {
  padding: 30px;
  transition: all ease-in-out 0.4s;
  background: #ffffff;
  box-shadow: 0px 0 60px 0 rgba(72, 86, 100, 0.2);
  border-radius: 10px;
  
}

.cursor-pointer{
  cursor: pointer;
}

.iit-box .card{
  min-height: 370px;
  padding: 10px;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 20px;
}

.iit-box .card img{
  width: 100px;
}

.whyMarquee .card{
  
  padding: 10px;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 20px;
}

.news-block{
  border: none;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;

}

.news-dates h6{
  font-weight: bold;
  font-size: 20px;
}

.news-body span{
  font-weight: bold;
}

.news-body h4{
  font-size: 28px;
  color: #014C7A !important;
  
  padding-bottom: 20px;
}

.news-body h6{
  font-weight: bold;
}

.text-blue{
  color: #014C7A
}
.new-shadow{
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.productmin-height{
  min-height: 70px;
}

.p-card  .card-title{
  color: #014C7A;
}